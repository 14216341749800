<div class="overview_area secondary-bg-color ptb-100">
    <div class="holder">
        <div class="hr">
           
            
            <div class="col-12" >
                <div class="overview_text_inner align-center" style="border-radius: 70px;">
                    <div class="section_title align-center">
                        <div class="section_sub_title">
                            <p>Welcome to Hotel Kalinga</p>
                        </div>
                        <h1>Relax In Our Hotel</h1>
                    </div>
                    <div class="section_body">
                        <p>At Hotel Kalinga, we believe that true relaxation is an art, and we have perfected it to
                            provide you with an unforgettable experience. Our dedicated staff is committed to delivering impeccable service, attending to your
                            every need with a warm smile and genuine care. Whether you need assistance with travel
                            arrangements, local recommendations, or any other request, we're here to make your stay
                            truly seamless.
                            Hotel Kalinga is the ideal retreat that promises an experience of tranquility and
                            relaxation like no other. Book your stay with us and let us pamper you with exceptional
                            service and warm hospitality throughout your journey.</p>





                        <a routerLink="/aboutus" class="common_btn2 btn_hbreffect1">about us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>