<div class="cmn_banner_area banner_type7 bnr_overlay2" style="height: 400px; object-fit: scale-down;">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="bannerTitle">
          <span class="bannerTitle1" style="    background: #fdba35;
         
          letter-spacing: 2px;
          color: #000;
          font-weight: 500;
          padding: 8px 15px;
          display: inline-block;">Service</span> <br>
          <strong class="bannerTitle2" style="    background: rgba(0, 0, 0, 0.5);
          
          letter-spacing: 2px;
          color: #fff;
          padding: 8px 15px;
          display: block;
          font-weight: normal;"> Enhancing Your Stay at Hotel Kalinga</strong>
      </div>
        <!-- <div class="section_title align-center">
          <div class="section_sub_title ">
            <p class="text-center">resort service</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        
        <!-- <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center justify-content-cd--center">
            <li>
              <a routerLink="/home">
                Home
              </a>
            </li>
            <li class="active">
              Resort Service
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</div>



<!-- <div class="holder pt-5">
  <div class="row">
    <div class="col-md-6 mb-2">
      <img src="assets/images/details_img1.jpg" alt="">
    </div>

    <div class="col-md-6 mb-5">
      <div class="overview_text_inner align-center">
        <div class="section_title align-center">

          <h1>Flora Ecostay Lonavala </h1>
        </div>
        <div class="section_body">
          <p>Flora Ecostay is a Boutique resort in Lonavala just 2.5 km from city centre .
            We are a 10 rooms property with a configuration of :<br>
            <strong> 6 Rooms :</strong> Family Suite can accommodate 4 pax in each room has a Queen size bed in the hall
            and 2 single beds in the bedroom and 1 washroom with Hot water facility Shower, Television , Wifi , Air
            Conditioner, Bath Towels and Hand towels .<br><strong>
              4 Rooms :</strong> Standard Executive room can accommodate 2 pax has a Queen size bed and 1 washroom with
            Hot water facility Shower, Television , Wifi , Air Conditioner, Bath Towels and Hand towels .
            <br><strong> Dormitory :</strong> <br>
            Can accommodate 4 pax on bed and 2 extra mattress can be accommodated has 2 washrooms . AC, Television set
            is not available in the dormitory .
            <br><strong>Restaurant :</strong><br> We have a restaurant serving vegetarian and Non vegetarian food the
            restaurant has a seating capacity of 40 to 50 persons , Nescafe vending machine available .
          </p>
        </div>
      </div>
    </div>


  </div>
  <div class="row">
    <div class="col-md-6 mb-5">
      <div class="overview_text_inner align-center">
        <div class="section_title align-center">

          <h1>Group Bookings</h1>
        </div>
        <div class="section_body">
          <p>We have 10 rooms 6 are Family suites and 4 are standar rooms and a dormitory for 4 pax with 2
            bathrooms.<br>
            Beds available for 36 guests and 10 extra mattresses can be provided. Total 46 pax can be easily
            accommodated. <br>
            Rate for groups Bed and Breakfast : <br>
            <strong> Group Rates : </strong>
            1,500/- plus tax ( 12% gst ) per person per day includes stay and breakfast .<br>
            <strong>Vegetarian Menu Package :</strong> 1,000/- plus tax ( 10 %)<br>
            <strong>Non Vegetarian Meal Package :</strong> 1,200/- plus tax ( 10%) Includes non veg only in dinner .<br>
            <strong>Non Vegetarian Meal Package :</strong> 1,500/- plus taxes ( 10%) <br>
            Includes non veg in both meals .</p> <br>


        </div>
      </div>
    </div>

    <div class="col-md-6 mb-2">
      <img src="assets/images/details_img2.jpg" alt="">
    </div>
  </div>
  <div class="overview_text_inner row">
    <div class="col-md-12">
      <div class="section_title align-center">
        <h2>Menu</h2><br>
      </div>

    </div>
    <div class="col-md-4">
      <div class="section_title align-center overview_text_inner mb-2 p-1 m-1">
        <h4 style="color: green;">Vegetarian Menu Package : 1,000/-</h4>

        <strong>Lunch :</strong> <br>
        Roti / Naan / Paratha <br>
        1 Vegetable Main course <br>
        Steam Rice <br>
        Dal Fry <br>
        Raita , Papad and Pickle <br>
        1 sweet <br>

        <strong>Hi Tea :</strong><br> Mix veg Pakora or Maggi Noodles or Sandwiches<br>

        <strong>Dinner :</strong><br>

        <strong>Chinese :</strong> Vegetable fried rice / Veg Manchurian / Hakka Noodles<br>

        OR<br>

        Vegetable Pulao and Kadhi and 1 Veg Starter<br>

        OR<br>

        Pav Bhaji or Veg Pizza and Dal Khichdi and Kadhi .<br><br>

      </div>
    </div>
    <div class="col-md-4">
      <div class="section_title align-center overview_text_inner mb-2 p-1 m-1">
        <h4 style="color: red;">Menu Grid Non Veg Package 1,200/-</h4>

        <strong>Lunch :</strong><br>
        Roti / Naan / Paratha<br>
        1 Vegetable Main course<br>
        Steam Rice<br>
        Dal Fry<br>
        Raita , Papad and Pickle<br>
        1 sweet<br>

        <strong>Hi Tea :</strong><br> Mix veg Pakora or Maggi Noodles or Sandwiches<br>

        <strong>Dinner :</strong><br>

        <strong>Chinese :</strong> Egg fried rice / Chicken Manchurian / Chicken Hakka Noodles<br>

        OR<br>

        Tomato Egg drop Soup , Chicken biryani and 1 chicken starter.<br><br>

      </div>

    </div>
    <div class="col-md-4">
      <div class="section_title align-center overview_text_inner mb-2 p-1 m-1">
        <h4 style="color: red;">Menu Grid Non Veg Package 1,500/-</h4>

        <strong>Lunch :</strong><br>
        Roti / Naan / Paratha<br>
        1 Chicken Main course<br>
        Steam Rice<br>
        Dal Fry<br>
        Raita , Papad and Pickle<br>
        1 sweet<br>

        <strong>Hi Tea :</strong><br> Mix veg Pakora or Maggi Noodles or Sandwiches<br>

        <strong>Dinner :</strong><br>

        <strong>Chinese :</strong> Egg fried rice / Chicken Manchurian / Chicken Hakka Noodles<br>

        OR<br>

        Tomato Egg drop Soup , Chicken biryani and 1 chicken starter.<br>

        OR<br>

        Fish Curry ( King fish 1 piece per person ) with Steam Rice and Fish Fry 1 piece person ( Mackerel / Bombay duck
        /
        King fish ) as per availability<br>
      </div>
    </div>
  </div> -->

  <div class="service_area_type2 ptb-95-70 pt-5">
    <div class="holder">
      <div class="service_inner">
        <div class="hr">
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-cutlery" aria-hidden="true"></i>
              <h3>Restaurant</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-wifi"></i>
              <h3>wi-fi</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service align-center mb-30">
              <img src="assets/images/air-conditioner.png" style="height: 49px;width: 59px; margin-top: -5px;" /> 
              <h3 style="margin-top: 15px;">Air Conditioner</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
               <img src="assets/images/Geyser.png" style="height: 48px; margin-top: -5px;" /> 
              <h3 style="margin-top: 15px;">Geyser</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>

        </div>
        <div class="hr">
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-bed" aria-hidden="true"></i>
              <h3>Room Service</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service align-center mb-30">
              <i class="fa fa-television" aria-hidden="true"></i>
              <h3>LED TV</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-camera" aria-hidden="true"></i>
              <h3>Security</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-car" aria-hidden="true"></i>
              <h3>Car parking</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
