<div class="cmn_banner_area banner_type13 bnr_overlay2" style="height: 400px; object-fit: scale-down;">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="bannerTitle">
          <span class="bannerTitle1" style="    background: #fdba35;
         
          letter-spacing: 2px;
          color: #000;
          font-weight: 500;
          padding: 8px 15px;
          display: inline-block;">Contact Us</span> <br>
          <strong class="bannerTitle2" style="    background: rgba(0, 0, 0, 0.5);
          
          letter-spacing: 2px;
          color: #fff;
          padding: 8px 15px;
          display: block;
          font-weight: normal;">Get in Touch with Us</strong>
      </div>
        <!-- <div class="section_title align-center">
          <div class="section_sub_title">
            <p>contact</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <!-- <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center justify-content-cd--center">
            <li>
              <a routerLink="/home"> Home </a>
            </li>
            <li class="active">contact</li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class="contact_N_map_area ptb-100">
  <div class="holder">
    <div class="hr">
      <div class="vc-sd-6">
        <div class="contact_inner">
          <h2>Drop Us A Message</h2>
          <p>If You Have Any Query , Don't Hesitate to Contact With Us</p>
          <form *ngIf="success == false" #contactForm="ngForm" (ngSubmit)="submitForm(contactForm)" role="form">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-field">
                  <label>Full Name*</label>
                  <input type="text" name="name" [(ngModel)]="name" class="form-control" placeholder="Full Name" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-field">
                  <label>Your Email*</label>
                  <input type="email" required name="email" email ngModel class="form-control" placeholder="Email" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-field">
                  <label>Subject*</label>
                  <input type="text" class="form-control" name="subject" [formControl]="subject" [(ngModel)]="subjects"
                    required placeholder="Subject" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-field">
                  <label>Your Message</label>
                  <textarea require class="form-control" d name="message" ngModel rows="5"></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-submit">
                  <button type="submit" [disabled]="!contactForm.valid" class="btn" style="background-color: #fdba35">
                    Send Message <i class="la la-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div *ngIf="success" class="alert alert-success alert-dismissible fade show" role="alert" [@animate]="{
              value: '*',
              params: {
                y: '-120px',
                opacity: '0',
                delay: '100ms',
                duration: '400ms'
              }
            }">
            <button type="button" class="close" (click)="success = false">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>Your Message Sent succefully</strong>
          </div>
        </div>
      </div>
      <div class="vc-sd-6">
        <div class="map_inner">
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.8681463338917!2d85.82944927387156!3d20.26430241359816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a74ab0cd96d1%3A0x6788db5a4db189eb!2sCollection%20O%20Hotel%20Kalinga%20Bapuji%20Nagar!5e0!3m2!1sen!2sin!4v1694424246245!5m2!1sen!2sin"
              width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy">
            </iframe>
          </div>
          <!-- <div>

            <iframe
              src="{{token.getProperty().latitude}}"
              width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div> -->

          <div class="map_info">
            <div class="hr">
              <div class="col-12">
                <!-- <p>{{token.getProperty().address.streetName}} {{token.getProperty().address.streetNumber}}
                  {{token.getProperty().address.suburb}} {{token.getProperty().address.city}}
                  {{token.getProperty().address.state}} {{token.getProperty().address.country}}
                  {{token.getProperty().address.postcode}}
                </p> -->
                <div class="icon_numb">

                  <div class="row">
                    <div class="col-1" ><span class="info_icon"><i class="fa fa-home"></i></span></div>
                    <div class="col-2" style=" margin-top: 5px; margin-left: 30px; "><strong><h5>Address:</h5> </strong></div>
                    <div class="col" style="text-align: left; margin-top: 5px;margin-left: 15px;"><h5> {{ property.address.streetName }}
                      {{ property.address.streetNumber }}
                      {{ property.address.suburb }}, {{ property.address.city }},
                      {{ property.address.state }},
                      {{ property.address.country }},
                      {{ property.address.postcode }}</h5></div>
                  </div>

                  <!-- <h6><span class="info_icon"><i class="fa fa-home"></i></span><strong>Address:</strong>
                    {{ property.address.streetName }}
                    {{ property.address.streetNumber }}
                    {{ property.address.suburb }}, {{ property.address.city }},
                    {{ property.address.state }},
                    {{ property.address.country }},
                    {{ property.address.postcode }}</h6> -->
                </div>

                <div class="icon_numb">


                  <h5> <span class="info_icon"><i class="fa fa-mobile"></i></span><a href="tel:+{{token.getProperty().mobile}}">{{token.getProperty().mobile}}</a></h5>

                </div>
                <div class="icon_numb">

                <h5><span class="info_icon"><i class="fa fa-envelope"></i></span><a routerLink="mailto::hotelkalinga@kttorissa.com">hotelkalinga@kttorissa.com</a></h5>
                </div>
              </div>
              <!-- <div class="vc-sd-11 vc-sd-offset-1 vc-od-6 vc-od-offset-0 no-gap">
                <p>
                  <span>phone number:</span><a href="tel:+{{ token.getProperty().mobile }}">{{
                    token.getProperty().mobile
                  }}</a>
                </p>



                <p class="highlt">24/7 Available</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
