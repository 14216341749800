import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  galleryImage = [
    {
      imageURL: 'assets/images/Kalinga 1a.JPG',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga 2a.jpg',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga 3a.JPG',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga 4a.JPG',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga 5a.JPG',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga6a.JPG',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga 7a.jpg',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga 8a.JPG',
      title: 'Hotel',
      class: 'hotel'
    },
    {
      imageURL: 'assets/images/Kalinga9a.JPG',
      title: 'Rooms',
      class: 'rooms'
    },
    {
      imageURL: 'assets/images/Kalinga10a.JPG',
      title: 'Rooms',
      class: 'rooms'
    },
    {
      imageURL: 'assets/images/Kalinga11a.JPG',
      title: 'Rooms',
      class: 'rooms'
    },
    {
      imageURL: 'assets/images/Kalinga12a.JPG',
      title: 'Rooms',
      class: 'rooms'
    },
    {
      imageURL: 'assets/images/Kalinga13a.JPG',
      title: 'Rooms'
    },
    {
      imageURL: 'assets/images/Kalinga14a.JPG',
      title: 'Rooms'
    },
    {
      imageURL: 'assets/images/Kalinga15a.JPG',
      title: 'Rooms'
    },
    {
      imageURL: 'assets/images/Kalinga16a.JPG',
      title: 'Rooms'
    },
    
    // {
    //   imageURL: 'assets/images/gallery_img16.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img17.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img18.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img19.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img20.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img21.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img22.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img23.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img24.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img25.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img26.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img27.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img28.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img29.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img30.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img31.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img32.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img33.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img34.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img35.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img36.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img37.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img38.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img39.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img40.jpg',
    //   title: 'Resort'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img41.jpg',
    //   title: 'Resort'
    // },
  ]
  property: Property;
  constructor(
    private router: Router,
    private token: TokenStorage,
    private apiService: ApiService
  ) {
    this.property = new Property();
    if (this.token.getProperty() !== null) {
      this.property = this.token.getProperty();
    } else {
      this.getProperty();
    }
  }

  ngOnInit(): void {
  }
  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(response => {

      this.property = response.body;
      this.token.saveProperty(this.property);
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
    );
  }
// getGallery(){}


}
