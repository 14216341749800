<div class="cmn_banner_area banner_type4 bnr_overlay2">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="bannerTitle">
          <span class="bannerTitle1" style="    background: #fdba35;
         
          letter-spacing: 2px;
          color: #000;
          font-weight: 500;
          padding: 8px 15px;
          display: inline-block;">Our Rooms</span> <br>
          <strong class="bannerTitle2" style="    background: rgba(0, 0, 0, 0.5);
          
          letter-spacing: 2px;
          color: #fff;
          padding: 8px 15px;
          display: block;
          font-weight: normal;">Enhancing Your Stay</strong>
      </div>
        <!-- <div class="section_title align-center">
          <div class="section_sub_title">
            <p>all kinds of rooms</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <!-- <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center
            justify-content-cd--center">
            <li>
              <a routerLink="/home">
                Home
              </a>
            </li>
            <li class="active">
              Rooms
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</div>


<div class="rooms_details_area ptb-95-70">
  <div class="holder">
    <div class="rooms_details_inner secondary-bg-color" *ngFor="let room of rooms;">
      <div class="hr">
        <div class="vc-sd-6 vc-od-4">
          <div class="room_details_img align-center">
            <!-- <img src="assets/images/room-detail-img5.jpg" alt="rooms"> -->
            <img *ngIf="room.imageList?.length" src="{{ room.imageList[0].url}}" alt="" width="390" height="335">
          </div>
        </div>




        <div class="vc-sd-6 vc-od-5">
          <ul class="rooms_details">
            <li>
              <div class="">
                <div *ngIf="room.roomFacilities?.length">
                <div class="details_inner">
                  <!-- <span><i class="fa fa-wifi"></i></span> wifi -->

                  <div class="d-flex flex-wrap ">
                    <div class=" all_room_size"  *ngFor="let item of room.roomFacilities">
                      <span class="rooms_ditels_view" style="width: 100%; ">

                        <img [src]="item.imageUrl" width="25px" height="25px" color="yellow" alt="" class="mr-4 rounded">
                        <!-- <div class="media-body pb-3 p-1 mb-0 small lh-125 "> -->
                          <span style="font-size: 14px;color: #ce3e3c;">{{item.name}}</span>
                        <!-- </div> -->

                      </span>

                    </div>
                  </div>
                  </div>
                </div>
                <!-- <div class="details_inner">
                  <span><i class="fa fa-archive"></i></span> Mini bar
                </div>  -->
              </div>
            </li>

            <!-- <li>
              <div class="rooms_ditels_view">
                <div class="details_inner">
                  <span><i class="fa fa-coffee"></i></span> Tea & Coffee
                </div>
                <div class="details_inner">
                  <span><i class="fa fa-lock"></i></span> Security Service
                </div>
              </div>
            </li> -->

          </ul>
        </div>
        <div class="vc-sd-12 vc-od-3">
          <div class="rooms_details_view align-center">
            <h3 class="romans">{{room.name}}</h3>
            <h3 class="bold"><span>₹</span>{{room.roomOnlyPrice}}</h3>
            <a routerLink="/booking/choose" target="blank" class="common_btn3 spacial_btn">Book now</a>
            <p>per night</p>
            <p class="highlt">available</p>
            <!-- <div class="room_icon">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                      </div> -->
            <p>Accommodates upto
              {{room.maximumOccupancy}} pax <br> No. Of Rooms {{room.noOfRooms}}</p>
              <p>{{room.description}}</p>
          </div>

        </div>

      </div>

    </div>



    <!-- <div class="pagination_area">
          <div class="hr">
              <div class="vc">
                  <div class="nav-links nav-links-bordered default-nav-links ">
                      <a href="#" class="active">
                          1
                      </a>
                      <a href="#">
                          2
                      </a>
                      <a href="#">
                          3
                      </a>
                  </div>
              </div>
          </div>
      </div> -->
  </div>
</div>
