<div class="cmn_banner_area banner_type14 bnr_overlay2" style="height: 400px; object-fit: scale-down;">
  <div class="holder">
      <div class="hr">
          <div class="vc">
            <div class="bannerTitle">
                <span class="bannerTitle1" style="    background: #fdba35;
               
                letter-spacing: 2px;
                color: #000;
                font-weight: 500;
                padding: 8px 15px;
                display: inline-block;">Gallery</span> <br>
                <strong class="bannerTitle2" style="    background: rgba(0, 0, 0, 0.5);
                
                letter-spacing: 2px;
                color: #fff;
                padding: 8px 15px;
                display: block;
                font-weight: normal;">Exploring Hotel Kalinga</strong>
            </div>
              <!-- <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p>Gallery</p>
                  </div>
              </div> -->
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <!-- <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink="/home">
                              Home
                          </a>
                      </li>
                      <li class="active">
                          Gallery
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
  </div>
</div>


<div class="gallery_area_type1 ptb-70">
  <div class="holder">
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_filter align-center">
                  <!-- <ul class="filter" id="grid">
                      <li class="active" data-filter="*">
                          <span class="common_btn">All Photos</span>
                      </li>
                      <li data-filter=".rooms">
                          <span class="common_btn">Rooms</span>
                      </li>
                      <li data-filter=".restaurant" class="">
                          <span class="common_btn">Restaurant & Club</span>
                      </li>
                      <li data-filter=".hotel">
                          <span class="common_btn">Resort</span>
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
      <div class="hr">
        <div class="vc-sd-12">
            <div class="gallery_item align-center">
                <div class="gallery_item1 row">
                    <div *ngFor="let gallery of galleryImage" class="col-12 col-sm-6 col-md-4 col-lg-3 {{gallery.class}}">
                        <div class="item_content">
                            <div class="single_grid_item">
                                <img src="{{gallery.imageURL}}" alt="gallery">
                                <a routerLink="{{gallery.imageURL}}" class="singel_item_hover_content restaurant_menu_image" data-gall="smg">
                                    <h5>{{gallery.title}}</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
  </div>
</div>

