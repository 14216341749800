<div class="preloader">
  <div class="loader">
    <span class="inner-loader">
      <img *ngIf="property.logoUrl !== null && property.logoUrl !== undefined" [src]="property.logoUrl" alt="" class="logospin"/>
      <img *ngIf="property.logoUrl == null || property.logoUrl == undefined" src="assets/images/Logo-kalinga.png" alt="" class="logospin"/>

    </span>
  </div>
</div>

<header class="navbar-fixed">


  <app-navbar></app-navbar>
</header>
