<div class="banner_area_type3 bnr_overlay2" style="height: 400px; object-fit: scale-down;">
    <div class="holder">
        <div class="hr">
            <div class="vc">
                <div class="bannerTitle">
                    <span class="bannerTitle1" style="    background: #fdba35;
                   
                    letter-spacing: 2px;
                    color: #000;
                    font-weight: 500;
                    padding: 8px 15px;
                    display: inline-block;">About Us</span> <br>
                    <strong class="bannerTitle2" style="    background: rgba(0, 0, 0, 0.5);
                    
                    letter-spacing: 2px;
                    color: #fff;
                    padding: 8px 15px;
                    display: block;
                    font-weight: normal;">Your Home Away from Home</strong>
                </div>
                <!-- <div class="banner_section_title align-center">
                    <div class="section_sub_title">
                        <p>about us</p>
                    </div>
                    <h1>A Home Away From Home</h1>
                </div> -->
            </div>
        </div>
        <!-- <div class="hr">
            <div class="vc">
                <div class="bradecrumbe">
                    <ul class="breadcrumb justify-content-center justify-content-cd--center">
                        <li>
                            <a routerLink='/'>
                                Home
                            </a>
                        </li>
                        <li class="active">
                            About Us
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="holder pt-5">
    <div class="row">
        <div class="col-md-6 mb-2 margin_top ">
            <ngx-slick-carousel
                class="camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
                #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let gallery of slideImage" class="slide camera_src camerastarted ">
                    <img class=" " src="{{gallery.imageUrl}}" alt="First slide"
                        style="width: 500px;object-fit: scale-down;">
                    <!-- <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}"
                    style="background-size: cover;" class="item mfp-gallery cover"
                    title="{{gallery.title}}"></a> -->
                </div>
            </ngx-slick-carousel>
        </div>

        <div class="col-md-6 mb-5">
            <div class="overview_text_inner align-center">
                <div class="section_title align-center">

                    <h1>Hotel Kalinga </h1>
                </div>
                <div class="section_body">
                    <p>Hotel Kalinga is a well-known establishment located in Bhubaneswar. It offers comfortable
                        accommodations, excellent dining options, and a range of amenities for both business and leisure
                        travelers. With its convenient location and hospitable staff, Hotel Kalinga ensures a pleasant
                        stay for guests looking to explore the area or conduct business in the city.
                    </p>
                </div>
            </div>
        </div>


    </div>
</div>



