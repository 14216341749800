<div class="hotel_service_type1 pt-5">
  <!-- Info Section -->
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center">
          <div class="section_sub_title">
            <h1>{{title}}</h1>
          </div>
          <p>{{desc}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Info Section / End -->
  <!-- Categories Carousel -->

  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <!-- <img src="assets/images/Kalinga17a.JPG" class="d-block w-100" alt="Hotel Kalinga"> -->
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="review-card">
                  <h3 style="text-align: center;">Aditya Aditya</h3>
                  <p style="text-align: center;">Actually the location good
                    food best
                    staff behavior best
                    Overall hotel price and the service was so good</p>
                  <p class="star-rating" style="text-align: center; font-size: 30px;">&#9733; &#9733; &#9733; &#9733;
                    &#9733; <!-- 5-star rating --></p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="review-card">
                  <h3 style="text-align: center;">Ayashkant Bishi</h3>
                  <p style="text-align: center;">The room was spacious, clean, and well-appointed, with a comfortable
                    bed that made for a restful night's sleep. </p>
                  <p class="star-rating" style="text-align: center; font-size: 30px;">&#9733; &#9733; &#9733; &#9733;
                    &#9733; <!-- 5-star rating --></p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="review-card">
                  <h3 style="text-align: center;">Pranaya Behera</h3>
                  <p style="text-align: center;">Overall good place. Service and staff behaviour also good. But price is fixed 1400/- ...so don't believe in oyo showing less price.</p>
                  <p class="star-rating" style="text-align: center; font-size: 30px;">&#9733; &#9733; &#9733; &#9733;
                    &#9733; <!-- 5-star rating --></p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="carousel-item">
        <img src="assets/images/Kalinga9a.JPG" class="d-block w-100" alt="Hotel Kalinga">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Kalinga 1a.JPG" class="d-block w-100" alt="Hotel Kalinga">
      </div> -->
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>




</div>

<div class="hotel_service_type1 secondary-bg-color pt-5">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center">
          <div class="section_sub_title">
            <p>Hotel Kalinga</p>
          </div>
          <h1>Room service</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="hotel_service_inner">
    <div class="single_hotel_service" *ngFor="let item of galleryItems">
      <img src="{{item.imageUrl}}" alt="">
      
      <div class="image_details">
        <h3>{{item.title}}</h3>
  <!-- <p>starting $ 120</p> -->
  </div>
    </div>
  </div>
</div>

  